import React, { FC, useState } from "react";
import { Col, Form, Input, Row } from "antd";
// import "react-phone-input-2/lib/style.css";

import { FirstWrapper } from "./styles";
import { useGlobalContext } from "../../../hooks/globalContext";

interface IProps {
  practiceName: string;
  setPracticeName: (value: string) => void;

  phoneNumber: string;
  setPhoneNumber: (value: string) => void;

  email: string;
  setEmail: (value: string) => void;

  name: string;
  setName: (value: string) => void;
}

export const Contact: FC<IProps> = ({
  practiceName,
  setPracticeName,
  phoneNumber,
  setPhoneNumber,
  email,
  setEmail,
  name,
  setName,
}) => {
  const { setUserDetails } = useGlobalContext({});
  const [showErrorMessage, setShowErrorMessage] = useState<boolean>(false);
  const [form] = Form.useForm();

  const onFinishFailed = () => {
    setShowErrorMessage(true);
  };

  const formatPhoneNumberValue = (value: string | any) => {
    if (!value) return value;
    const phoneNumber = value?.replace(/[^\d]/g, "");
    const phoneNumberLength = phoneNumber?.length;
    if (phoneNumberLength < 4) return phoneNumber;
    if (phoneNumberLength < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
      3,
      6
    )}- ${phoneNumber.slice(6, 10)}`.replace(/- /g, "-");
  };

  const handlePhoneNumberChange = (e: any) => {
    const formattedPhoneNumber = formatPhoneNumberValue(e?.target?.value);
    setPhoneNumber(formattedPhoneNumber ? formattedPhoneNumber : "");
  };

  const validateAndFormatEmail = (value: string | any): string => {
    if (!value) return value;

    // Regular expression for validating an Email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    const isValidEmail = emailRegex.test(value);

    return isValidEmail ? value : "";
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const formattedEmail = validateAndFormatEmail(e.target.value);
    setEmail(formattedEmail);
  };

  return (
    <FirstWrapper>
      <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <Form
            form={form}
            name="contact-info"
            layout="vertical"
            onFinishFailed={onFinishFailed}
          >
            <Form.Item
              label="Your Company Name"
              required
              rules={[
                { required: true, message: "Please Input Your Company Name!" },
              ]}
            >
              <Input
                required
                placeholder="Company Name"
                value={practiceName}
                onChange={(e) => setPracticeName(e.target.value)}
              />
            </Form.Item>
            <Form.Item
              label="Your Name"
              required
              rules={[{ required: true, message: "Please input your Name!" }]}
            >
              <Input
                placeholder="Name"
                // defaultValue={name}
                value={name}
                onChange={(e) => setName(e.currentTarget.value)}
                // onBlur={(e) =>
                //   setUserDetails({ username: e.currentTarget.value })
                // }
              />
            </Form.Item>
            <Form.Item
              label="Your Phone Number"
              required
              rules={[{ required: true, message: "Phone is required" }]}
            >
              <Input
                onChange={(e) => handlePhoneNumberChange(e)}
                value={phoneNumber}
                placeholder="Enter your phone number"
              />
              <span style={{ color: "red" }}>
                {showErrorMessage && !phoneNumber
                  ? "Phone Number is Required"
                  : ""}
              </span>
            </Form.Item>

            <Form.Item
              name="email"
              label="Your Email Address"
              rules={[
                { required: true, message: "Please input your Email Address!" },
                {
                  type: "email",
                  message: "Please enter a valid email address!",
                },
              ]}
            >
              <Input
                required
                placeholder="Email Address"
                onChange={handleEmailChange}
                // onChange={(e) => setEmail(e.target.value)}
                value={email || ""}
                defaultValue={email}
              />
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </FirstWrapper>
  );
};

export default Contact;
