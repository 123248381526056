import styled from "styled-components";

export const HeaderWrapper = styled.div`
  top: 0;
  z-index: 99;
  position: sticky;

  .main-div {
    background-color: rgba(230, 247, 255, 1);
    border-bottom: 1px solid #e5e5e5;
    display: flex;
    padding: 0 0 0 2px;
    margin: 0;
  }

  .row {
    width: 100%;
  }

  .col {
    display: flex;
    justify-content: space-between;
  }

  .logo-image {
    margin-top: 10px;
    margin-left: 10px;
    float: left;
    padding: 4px;
  }

  .avatar-div {
    display: flex;
    padding-right: 15px;
    margin-top: 2px;
  }

  .avatar-style {
    margin-top: 10px;
    margin-right: 5px;
    border-radius: 3px;
  }
`;
