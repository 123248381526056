import styled from "styled-components";

export const DashboardWrapper = styled.div`
  height: 93.5vh;
  background-color: #e5e5e5;
  padding: 0 0 0 0;
  border-radius: 2px;

  .card-element {
    width: 100%;
    border-radius: 1px;
    text-align: left;
    background: #ffffff;

    .ant-card-body {
      width: 100%;

      .card-div {
        display: flex;
        justify-content: space-between;
      }
    }
  }

  .space-button {
    margin-bottom: 10px;
  }

  .card-element-two {
    width: 100%;
    height: auto;
    border-radius: 1px;
    text-align: left;
    background: #ffffff;
  }

  .span-element {
    font-size: 20px;
    font-weight: 500;
  }
  @media (max-width: 600px) {
    .span-element {
      font-size: 18px;
    }
  }

  .step-div {
    text-align: center;
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .button-style {
    border-radius: 1px;
  }

  .main-div {
    display: flex;
    justify-content: center;
  }

  .content-div {
    display: flex;
    justify-content: flex-end;
    width: 50%;
  }
  .cancel-button-style {
    // margin-right: -0.2% !important;
  }

  ul {
    list-style-type: none;
    padding-left: 0;
    text-align: justify;
  }
`;
