import styled from "styled-components";

export const YesWrapper = styled.div`
  width: 100%; /* Change the width to your desired size */
  margin: 0 auto; /* Centers the form horizontally */
  display: block;
  .patient-samples {
    width: 45%;
  }
`;
