import { FC } from "react";
import { Col, Input, Radio, Row } from "antd";
import { NoWrapper } from "./styles";

interface IProps {
  docNumber: string;
  setDocNumber: (value: string) => void;

  labDays: string;
  setLabDays: (value: string) => void;
}

export const PotentialNo: FC<IProps> = ({
  docNumber,
  setDocNumber,
  labDays,
  setLabDays,
}) => {
  return (
    <NoWrapper>
      <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <p>Number of doctors:</p>
          <Row>
            <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6}>
              <Input
                placeholder="Number of Doctors"
                className="doctor-number"
                value={docNumber}
                onChange={(e) => setDocNumber(e.target.value)}
              />
            </Col>
          </Row>

          <p>Select how many days per week your lab operates:</p>
          <Radio.Group
            onChange={(e) => setLabDays(e.target.value)}
            value={labDays}
          >
            <Radio value={5}>5 Days</Radio>
            <Radio value={6}>6 Days</Radio>
            <Radio value={7}>7 Days</Radio>
          </Radio.Group>
        </Col>
      </Row>
    </NoWrapper>
  );
};

export default PotentialNo;
