import { createContext, useContext } from "react";
import { IUser, NOTIFICATIONTYPE } from "../models";

interface IGlobalContextvalues {
  userDetails?: IUser;
  setUserDetails?: (payload: IUser) => void;
  notificationCallback?: (type: NOTIFICATIONTYPE, message?: string) => void;
  exportData: any;
  setExportData: (value: any) => void;
}

export const globalContext: IGlobalContextvalues | any = createContext({});
export const useGlobalContext: any = () => useContext(globalContext);
