import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { styled } from "styled-components";

const antIcon = <LoadingOutlined style={{ fontSize: 30 }} spin />;

const AppLoading = () => {
  return (
    <WrapperLoader>
      <div className="lis_loader">
        <p>Application is loading, please wait...</p>
        <Spin indicator={antIcon} />
      </div>
    </WrapperLoader>
  );
};

export default AppLoading;
const WrapperLoader = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;

  .lis_loader {
    display: flex;
    flex-direction: column;
    height: 100px;
    justify-content: space-between;

    p {
      font-size: 18px;
      color: #757575;
      font-weight: 500;
    }
  }
`;
