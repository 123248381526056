import http from "src/utils/http";

export const downloadExcelApi = async (payload: any) => {
  try {
    const response = await http.post(
      `https://test-lis.precipiodx.com/api/auth/proforma/export`,
      payload,
      {
        responseType: "arraybuffer",
        headers: {
          Accept: "*/*",
        },
      }
    );

    return response;
  } catch (error) {
    console.error("Error in downloadExcelApi:", error);
    throw error;
  }
};
