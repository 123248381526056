import { Outlet } from "react-router";
import { Layout } from "antd";

import HeaderComponent from "../components/header/header";
import styled from "styled-components";

const { Content } = Layout;

const AppLayout: any = () => {
  return (
    <Layout>
      <HeaderComponent />
      <LayoutWrapper>
        <Layout className="component-layout">
          <Content>
            <Outlet />
          </Content>
        </Layout>
      </LayoutWrapper>
    </Layout>
  );
};

export default AppLayout;

const LayoutWrapper = styled.div`
  .component-layout {
    // overflow-y: scroll;
  }

  ::-webkit-scrollbar-track {
    border-radius: 1px !important;
    background-color: #f5f5f5;
  }

  ::-webkit-scrollbar {
    width: 0.6rem;
    background-color: #f5f5f5;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: #1890ff;
  }
`;
