import styled from "styled-components";

export const AccessWrapper = styled.div`
  height: 70vh;
  padding-top: 1%;
  padding-bottom: 7%;

  .access-card {
    padding: 0px 60px 50px 60px;
  }

  .access-input {
    margin: 0% 0% 5% 0%;
  }

  .access-button {
    width: 100%;
  }
`;
