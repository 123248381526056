export const constMonthlyVolumes = [
  { panel: "BCR-ABL1", value: 3 },
  { panel: "ABL1 resistance", value: 1 },
  { panel: "MPN", value: 2 },
  { panel: "AML", value: 0.25 },
  { panel: "Cytopenia", value: 3 },
  { panel: "CLL", value: 2 },
];

export const minSizePlate = [
  { panel: "BCR-ABL1", value: 4 },
  { panel: "ABL1 resistance", value: 3 },
  { panel: "MPN", value: 4 },
  { panel: "AML", value: 1 },
  { panel: "Cytopenia", value: 3 },
  { panel: "CLL", value: 3 },
];

export const pricingReimbursement = [
  { panel: "BCR-ABL1", pricing: 200, cms: 570 },
  { panel: "ABL1 resistance", pricing: 100, cms: 300 },
  { panel: "MPN", pricing: 250, cms: 596 },
  { panel: "AML", pricing: 450, cms: 1602 },
  { panel: "Cytopenia", pricing: 312.5, cms: 1298 },
  { panel: "CLL", pricing: 375, cms: 1262 },
];

export const panelProFormaLabels = [
  { name: "Practice Volume" },
  { name: "CMS Reimbursement" },
  { name: "Revenues" },
  { name: "Reagent Costs" },
  { name: "Profit" },
  { name: "Profit Margin" },
];

export const reagentPerSample = [
  { panel: "BCR-ABL1", reagent: 200 },
  { panel: "ABL1 resistance", reagent: 100 },
  { panel: "MPN", reagent: 250 },
  { panel: "AML", reagent: 450 },
  { panel: "Cytopenia", reagent: 313 },
  { panel: "CLL", reagent: 375 },
];

export const consumeablePerSample = [
  { panel: "BCR-ABL1", consume_able: 40 },
  { panel: "ABL1 resistance", consume_able: 40 },
  { panel: "MPN", consume_able: 30 },
  { panel: "AML", consume_able: 30 },
  { panel: "Cytopenia", consume_able: 30 },
  { panel: "CLL", consume_able: 30 },
];

export const panelMonthlyList: any = [
  "bcr_monthly",
  "abl_monthly",
  "mpn_monthly",
  "aml_monthly",
  "cytopenia_monthly",
  "cll_monthly",
];

export const panelAnnuallyList: any = [
  "bcr_annually",
  "abl_annually",
  "mpn_annually",
  "aml_annually",
  "cytopenia_annually",
  "cll_annually",
];
