import React, { FC, useEffect } from "react";
import { AxiosResponse } from "axios";
import { useGlobalContext } from "src/hooks/globalContext";
import { NOTIFICATIONTYPE } from "src/models";

interface IProps {
  createRes: AxiosResponse<any> | undefined;
  errorRes: any;
  isError: boolean;
}

export const ErrorHandling: FC<IProps> = ({ createRes, errorRes, isError }) => {
  const { notificationCallback }: any = useGlobalContext();

  useEffect(() => {
    if (createRes?.data?.result) {
    } else if (errorRes && isError) {
      if (errorRes && errorRes.response && errorRes.response.data) {
        const { message } = errorRes.response.data;
        notificationCallback(NOTIFICATIONTYPE.ERROR, `${message}`);
      } else {
        notificationCallback(
          NOTIFICATIONTYPE.ERROR,
          `Connection has been lost! Please check your internet connection`
        );
      }
    }
  }, [createRes, isError, errorRes, notificationCallback]);
  return <></>;
};
