import styled from "styled-components";

export const TableWrapper = styled.div`
  .ant-table-thead > tr > th {
    background-color: #add8e6;
  }

  .ant-table-thead > tr > td {
    background-color: #add8e6;
  }
  .profit-row {
    background-color: #ccffd5;
  }
  .disclaimer-table {
    font-size: 0.72em;
  }

  .combined-column {
    background-color: #123674 !important;
    text-align: center;
    font-weight: bold;
    color: white;
    border-inline-end: 1px solid #123674;
  }
`;
