import styled from "styled-components";

export const AcknowledgementWrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  display: block;
  height: 30%;

  .circular-checkbox .ant-checkbox-inner {
    border-radius: 50%;
  }

  .concent {
    // margin-left: 15%;
    // position: absolute;
    // padding-bottom: 3%;
    // margin-top: 2%;
  }
  ul {
    list-style-type: none;
  }
`;
