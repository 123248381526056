import React, { FC, useState } from "react";
import { message } from "antd";

import { globalContext } from "./globalContext";
import { IUser, NOTIFICATIONTYPE } from "../models";

interface IProps {
  children: React.ReactElement<any>;
}

export const GlobalManager: FC<IProps> = ({ children }) => {
  const [userDetails, setUserDetails] = useState<IUser | any>(null);
  const [exportData, setExportData] = useState<any>(null);

  message.config({
    top: 101,
  });

  const notificationCallback = (type: any, info: string) => {
    switch (type) {
      case NOTIFICATIONTYPE.SUCCESS:
        message.success(info);
        break;
      case NOTIFICATIONTYPE.ERROR:
        message.error(info);
        break;
      case NOTIFICATIONTYPE.INFO:
        message.info(info);
        break;
      case NOTIFICATIONTYPE.WARNING:
        message.warning(info);
        break;
      default:
        message.success(info);
        break;
    }
  };

  return (
    <globalContext.Provider
      value={{
        userDetails, // user details
        setUserDetails,
        notificationCallback, // for popup notifications
        exportData,
        setExportData,
      }}
    >
      {children}
    </globalContext.Provider>
  );
};
