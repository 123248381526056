import Dashboard from "../modules/Dashboard";

export interface IRouteList {
  path: string;
  element: JSX.Element;
}

export const routeList: IRouteList[] = [
  {
    path: "/",
    element: <Dashboard />,
  },
];
