import type { FC } from "react";

import { Avatar, Col, Layout, Row } from "antd";
import { UserOutlined } from "@ant-design/icons";

import LISLogo from "../../assets/Lis.png";
import { HeaderWrapper } from "./styles";
import { useGlobalContext } from "../../hooks/globalContext";

const { Header } = Layout;

const HeaderComponent: FC = () => {
  const { userDetails } = useGlobalContext({});
  return (
    <HeaderWrapper>
      <Header className="main-div">
        <Row align="middle" className="row">
          <Col md={24} sm={24} xs={24} className="col">
            <div>
              <img src={LISLogo} alt="Logo Here" className="logo-image" />
            </div>
            {/* <div className="avatar-div">
              <Avatar
                className="avatar-style"
                shape="square"
                size="large"
                icon={<UserOutlined />}
              />
              <span style={{ fontWeight: "bold" }}>
                {userDetails?.username ?? "Username"}
              </span>
            </div> */}
          </Col>
        </Row>
      </Header>
    </HeaderWrapper>
  );
};

export default HeaderComponent;
