import React, { FC } from "react";
import Card from "antd/es/card/Card";
import { Button, Col, Input, Row } from "antd";
import { AccessWrapper } from "./styles";

interface IProps {
  accessCode: string;
  setAccessCode: (value: string) => void;
  onUnlockButtonClick: () => void;
}

export const AccessCode: FC<IProps> = ({
  accessCode,
  setAccessCode,
  onUnlockButtonClick,
}) => {
  const handleButtonClick = () => {
    onUnlockButtonClick();
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      onUnlockButtonClick();
    }
  };

  return (
    <AccessWrapper>
      <Row justify="center" align="middle">
        <Col lg={0} xl={0} md={0} sm={0} xxl={0} />
        <Col>
          <Card className="access-card">
            <h3 className="access-prompt">Please enter the access code</h3>
            <Input
              className="access-input"
              placeholder="Access Code"
              value={accessCode}
              onChange={(e) => setAccessCode(e.target.value)}
              onKeyPress={handleKeyPress}
            />
            <br />
            <Button
              className="access-button"
              onClick={handleButtonClick}
              type="primary"
              size="middle"
            >
              Access Pro Forma
            </Button>
          </Card>
        </Col>
      </Row>
    </AccessWrapper>
  );
};

export default AccessCode;
