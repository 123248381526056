import { useEffect, useState } from "react";
import { Route, Routes } from "react-router";

import "./App.css";
import AppLayout from "./layout";
import { IRouteList, routeList } from "./routes";
import AppLoading from "./components/Loading/loading";

function App() {
  const allRoutes: any = routeList;
  const [appLoading, setAppLoading] = useState<boolean>(true);

  useEffect(() => {
    setTimeout(() => {
      setAppLoading(false);
    }, 1500);
  }, []);

  useEffect(() => {
    const handleBeforeUnload = (event: any) => {
      event.preventDefault();
      // Custom logic to handle the refresh
      // Display a confirmation message or perform necessary actions
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  return (
    <div className="App">
      {appLoading ? (
        <AppLoading />
      ) : (
        <Routes>
          <Route element={<AppLayout />}>
            {allRoutes.map((item: IRouteList, index: number) => (
              <Route key={index} path={item?.path} element={item?.element} />
            ))}
          </Route>
        </Routes>
      )}
    </div>
  );
}

export default App;
