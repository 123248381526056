import styled from "styled-components";

export const CalculationWrapper = styled.div`
  width: 85%;
  margin: 0 auto;
  display: block;

  .custom-description {
    margin-left: 0px;
    margin-bottom: 40px;
    .ant-descriptions-header {
      margin: 0;
    }
    .ant-descriptions-item {
      padding: 0 !important;
      font-size: 12px;
    }
    .ant-descriptions-item-label: {
      font-size: 12px;
      border: 1px solid red;
    }
    .ant-descriptions-item-content {
      font-size: 13px;
      padding: 0;
      margin: 0;
    }
  }

  && tbody > tr:hover > td {
    background: #f5f5f5;
  }
  .ant-table-small .ant-table-thead > tr > th {
    border: 1px solid #ffff;
    font-size: 12px;
    padding: 3px !important;
    ::before {
      display: none;
    }
  }

  .ant-table-small .ant-table-tbody > tr > td {
    font-size: 12px;
    padding: 3px !important;
  }
  ul {
    list-style-type: none;
    padding-left: 0;
    text-align: justify;
    font-size: 8px;
  }
  .pricing-table {
    width: 70%;
  }
  .tat-table {
    width: 70%;
  }

  // .ant-table-content {
  //   table {
  //     border: 1px solid black;
  //     border-radius: 1px;
  //   }

  // .ant-table-cell {
  //   border: 1px solid black;
  //   border-radius: 0;

  //     th {
  //       border-radius: 1px;
  //     }
  //   }
  // }

  :where(.css-1adbn6x).ant-table-wrapper
    .ant-table-thead
    > tr
    > td:not(:last-child):not(.ant-table-selection-column):not(
      .ant-table-row-expand-icon-cell
    ):not([colspan])::before {
    // position: absolute;
    // /* top: 50%; */
    inset-inline-end: 0;
    width: 1px;
    height: 1.6em;
    // background-color: #f0f0f0;
    transform: translateY(-50%);
    transition: background-color 0.2s;
    content: "";
  }
  .ant-table-cell > td {
    border-right: none;
  }
`;
