import { FC } from "react";
import { Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { TableWrapper } from "./styles";

interface IProps {
  data: any[];
  loading: boolean;
}

export const ProformaTable: FC<IProps> = ({ data, loading }) => {
  function formatData(record: any, value: number) {
    switch (record.panel) {
      case "Practice Volume":
        return Math.round(value);
      case "Profit Margin":
        return `${Math.round(value)}%`;
      default:
        return <span>$ {Math.round(value).toLocaleString("en-US")}</span>;
    }
  }

  const redHeaderStyle = { background: "#123674" };

  const onHeaderCell = () => ({
    style: redHeaderStyle,
  });

  const columns: ColumnsType<any> = [
    {
      title: "",
      dataIndex: "panel",
      width: "180px",
      align: "left",
      render: (value: string) => (
        <div style={{ fontWeight: "bold" }}>{value}</div>
      ),
    },
    {
      title: <div style={{ fontWeight: "bold" }}>BCR-ABL1</div>,
      dataIndex: "volume",
      width: "100px",
      align: "center",
      children: [
        {
          title: (
            <div style={{ textAlign: "center", fontWeight: "bold" }}>
              Monthly
            </div>
          ),
          dataIndex: "bcr_monthly",
          key: "monthly",
          width: "50px",
          onCell: (record, index) => ({
            colSpan: index === 5 ? 2 : 1,
          }),
          render: (value: number, record: any, index: any) =>
            index === 5 ? (
              <span
                style={{
                  display: "flex",
                  justifyContent: "center",
                  color: "#2856a2",
                }}
              >
                {formatData(record, value ? value : Number(0))}
              </span>
            ) : value ? (
              formatData(record, value)
            ) : (
              ""
            ),
        },
        {
          title: (
            <div style={{ textAlign: "center", fontWeight: "bold" }}>
              Annually
            </div>
          ),
          dataIndex: "bcr_annually",
          key: "annually",
          width: "50px",
          onCell: (record, index) => ({
            colSpan: index === 5 ? 0 : 1,
          }),
          render: (value: any, record: any, index: any) =>
            index === 5 ? "" : formatData(record, value ? value : Number(0)),
        },
      ],
    },
    {
      title: <div style={{ fontWeight: "bold" }}>ABL1 Resistance</div>,
      dataIndex: "tat",
      width: "100px",
      align: "center",
      children: [
        {
          title: (
            <div style={{ textAlign: "center", fontWeight: "bold" }}>
              Monthly
            </div>
          ),
          dataIndex: "abl_monthly",
          key: "monthly",
          width: "50px",
          onCell: (record, index) => ({
            colSpan: index === 5 ? 2 : 1,
          }),
          render: (value: number, record: any, index: any) =>
            index === 5 ? (
              <span
                style={{
                  display: "flex",
                  justifyContent: "center",
                  color: "#2856a2",
                }}
              >
                {formatData(record, value ? value : Number(0))}
              </span>
            ) : value ? (
              formatData(record, value ? value : Number(0))
            ) : (
              ""
            ),
        },
        {
          title: (
            <div style={{ textAlign: "center", fontWeight: "bold" }}>
              Annually
            </div>
          ),
          dataIndex: "abl_annually",
          key: "annually",
          width: "50px",
          onCell: (record, index) => ({
            colSpan: index === 5 ? 0 : 1,
          }),
          render: (value: any, record: any, index: any) =>
            index === 5 ? "" : formatData(record, value ? value : Number(0)),
        },
      ],
    },
    {
      title: <div style={{ fontWeight: "bold" }}>MPN</div>,
      dataIndex: "days_per_month",
      width: "100px",
      align: "center",
      children: [
        {
          title: (
            <div style={{ textAlign: "center", fontWeight: "bold" }}>
              Monthly
            </div>
          ),
          dataIndex: "mpn_monthly",
          key: "monthly",
          width: "50px",
          onCell: (record, index) => ({
            colSpan: index === 5 ? 2 : 1,
          }),
          render: (value: any, record: any, index) =>
            index === 5 ? (
              <span
                style={{
                  display: "flex",
                  justifyContent: "center",
                  color: "#2856a2",
                }}
              >
                {formatData(record, value ? value : Number(0))}
              </span>
            ) : value ? (
              formatData(record, value ? value : Number(0))
            ) : (
              ""
            ),
        },
        {
          title: (
            <div style={{ textAlign: "center", fontWeight: "bold" }}>
              Annually
            </div>
          ),
          dataIndex: "mpn_annually",
          key: "annually",
          width: "50px",
          onCell: (record, index) => ({
            colSpan: index === 5 ? 0 : 1,
          }),
          render: (value: any, record: any, index: any) =>
            index === 5 ? "" : formatData(record, value ? value : Number(0)),
        },
      ],
    },
    {
      title: <div style={{ fontWeight: "bold" }}>AML</div>,
      dataIndex: "samples_per_run",
      width: "100px",
      align: "center",
      children: [
        {
          title: (
            <div style={{ textAlign: "center", fontWeight: "bold" }}>
              Monthly
            </div>
          ),
          dataIndex: "aml_monthly",
          key: "monthly",
          width: "50px",
          onCell: (record, index) => ({
            colSpan: index === 5 ? 2 : 1,
          }),
          render: (value: any, record: any, index) =>
            index === 5 ? (
              <span
                style={{
                  display: "flex",
                  justifyContent: "center",
                  color: "#2856a2",
                }}
              >
                {formatData(record, value ? value : Number(0))}
              </span>
            ) : value ? (
              formatData(record, value ? value : Number(0))
            ) : (
              ""
            ),
        },
        {
          title: (
            <div style={{ textAlign: "center", fontWeight: "bold" }}>
              Annually
            </div>
          ),
          dataIndex: "aml_annually",
          key: "annually",
          width: "50px",
          onCell: (record, index) => ({
            colSpan: index === 5 ? 0 : 1,
          }),
          render: (value: any, record: any, index: any) =>
            index === 5 ? "" : formatData(record, value ? value : Number(0)),
        },
      ],
    },
    {
      title: <div style={{ fontWeight: "bold" }}>Cytopenia</div>,
      dataIndex: "plate_size",
      width: "100px",
      align: "center",
      children: [
        {
          title: (
            <div style={{ textAlign: "center", fontWeight: "bold" }}>
              Monthly
            </div>
          ),
          dataIndex: "cytopenia_monthly",
          key: "monthly",
          width: "50px",
          onCell: (record, index) => ({
            colSpan: index === 5 ? 2 : 1,
          }),
          render: (value: any, record: any, index) =>
            index === 5 ? (
              <span
                style={{
                  display: "flex",
                  justifyContent: "center",
                  color: "#2856a2",
                }}
              >
                {formatData(record, value ? value : Number(0))}
              </span>
            ) : value ? (
              formatData(record, value ? value : Number(0))
            ) : (
              ""
            ),
        },
        {
          title: (
            <div style={{ textAlign: "center", fontWeight: "bold" }}>
              Annually
            </div>
          ),
          dataIndex: "cytopenia_annually",
          key: "annually",
          width: "50px",
          onCell: (record, index) => ({
            colSpan: index === 5 ? 0 : 1,
          }),
          render: (value: any, record: any, index: any) =>
            index === 5 ? "" : formatData(record, value ? value : Number(0)),
        },
      ],
    },
    {
      title: <div style={{ fontWeight: "bold" }}>CLL</div>,
      dataIndex: "run_per_days",
      width: "100px",
      align: "center",
      children: [
        {
          title: (
            <div style={{ textAlign: "center", fontWeight: "bold" }}>
              Monthly
            </div>
          ),
          dataIndex: "cll_monthly",
          key: "monthly",
          width: "50px",
          onCell: (record, index) => ({
            colSpan: index === 5 ? 2 : 1,
          }),
          render: (value: any, record: any, index) =>
            index === 5 ? (
              <span
                style={{
                  display: "flex",
                  justifyContent: "center",
                  color: "#2856a2",
                }}
              >
                {formatData(record, value ? value : Number(0))}
              </span>
            ) : value ? (
              formatData(record, value ? value : Number(0))
            ) : (
              ""
            ),
        },
        {
          title: (
            <div style={{ textAlign: "center", fontWeight: "bold" }}>
              Annually
            </div>
          ),
          dataIndex: "cll_annually",
          key: "annually",
          width: "50px",
          onCell: (record, index) => ({
            colSpan: index === 5 ? 0 : 1,
          }),

          render: (value: any, record: any, index: any) =>
            index === 5 ? "" : formatData(record, value ? value : Number(0)),
        },
      ],
    },
    {
      title: (
        <div
          className="combined-color"
          style={{ fontWeight: "bold", color: "white" }}
        >
          Combined
        </div>
      ),
      dataIndex: "run_per_days",
      width: "100px",
      align: "center",
      className: "combined-column",
      children: [
        {
          title: (
            <div
              style={{
                textAlign: "center",
                fontWeight: "bold",
                color: "white",
              }}
            >
              Monthly
            </div>
          ),

          dataIndex: "combined_monthly",
          onHeaderCell: () => onHeaderCell(),
          key: "monthly",
          width: "50px",

          onCell: (record, index) => ({
            colSpan: index === 5 ? 2 : 1,
          }),
          render: (value: any, record: any, index) =>
            index === 5 ? (
              <span
                style={{
                  display: "flex",
                  justifyContent: "center",
                  color: "#2856a2",
                }}
              >
                {formatData(record, value ? value : Number(0))}
              </span>
            ) : value ? (
              formatData(record, value ? value : Number(0))
            ) : (
              ""
            ),
        },
        {
          title: (
            <div
              style={{
                textAlign: "center",
                fontWeight: "bold",
                color: "white",
              }}
            >
              Annually
            </div>
          ),
          dataIndex: "combined_annually",
          key: "annually",
          onHeaderCell: () => onHeaderCell(),
          width: "50px",
          onCell: (record, index) => ({
            colSpan: index === 5 ? 0 : 1,
          }),

          render: (value: any, record: any, index: any) =>
            !value || index === 5 ? "" : formatData(record, value ? value : Number(0)),
        },
      ],
    },
  ];

  const rowClassName = (data: any, index: any) => {
    if (data.key == 4) {
      return "profit-row";
    } else {
      return "";
    }
  };

  return (
    <>
      <h3 className="mt-10">Customer Pro Forma</h3>
      <TableWrapper>
        <Table
          size="small"
          bordered
          dataSource={!loading ? data : []}
          columns={columns}
          pagination={false}
          loading={loading}
          rowClassName={rowClassName}
          scroll={{ x: 600 }}
        />
        <p className="disclaimer-table">
          * The above calculations of costs, profit and margin do not take into
          account labor, equipment, overhead and other costs and allocations
          that may be relevant to your lab.
        </p>
      </TableWrapper>
    </>
  );
};
