import { useEffect, useMemo, useState } from "react";
import { Button, Card, Col, Divider, Row, Steps } from "antd";
import { useMutation } from "react-query";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import moment from "moment";

import { Contact } from "./Contact";
import { DashboardWrapper } from "./styles";
import { Potential } from "./Potential";
import { CalculationComponent } from "./Calculation";
import Acknowledgement from "./Acknowledgement";
import { IPanels } from "src/models";
import { downloadExcelApi, downloadPdfApi } from "src/api";
import { ErrorHandling } from "src/components/Errorhandling";
import { useGlobalContext } from "src/hooks/globalContext";
import { AccessCode } from "./AccessCode";

interface IStepProps {
  title: string;
  content: React.ReactElement;
}

const Dashboard = () => {
  const { exportData } = useGlobalContext();
  const [current, setCurrent] = useState<number>(0);
  const [practiceName, setPracticeName] = useState<string | any>(null);
  const [phoneNumber, setPhoneNumber] = useState<string | any>(null);
  const [name, setName] = useState<string | null>(null);
  const [email, setEmail] = useState<string | any>(null);
  const [labDays, setLabDays] = useState<string | any>(null);
  const [docNumber, setDocNumber] = useState<string | any>(null);
  const [accept, setAccept] = useState<boolean>(false);
  const [currentVolume, setCurrentVolume] = useState<boolean | null>(false);
  const [accessCode, setAccessCode] = useState<string | any>(null);
  const [isValidAccessCode, setIsValidAccessCode] = useState<boolean>(false);
  const [counter, setCounter] = useState(0);

  const generateRequestNumber = () => {
    const currentUnixTime = Date.now();

    // // Use counter to ensure uniqueness within the same second
    // const lastDigits = counter.toString().padStart(1, "0").slice(-3);

    // // Concatenate the last digits to ensure exactly 5 digits
    // // const requestNumber = parseInt(`${currentUnixTime}${lastDigits}`, 10);
    // // requestNumber = requestNumber.slice(-5);
    // const preRequestNumber = `${currentUnixTime}${lastDigits}`;
    // const requestNumber = parseInt(`${preRequestNumber.slice(-5)}`, 10);

    // // Increment counter for the next request
    // setCounter((prevCounter) => (prevCounter + 1) % 1000);

    return currentUnixTime;
  };

  const requestNumber: number = useMemo(() => generateRequestNumber(), []);

  // let requestNumber: number = useMemo(() => moment().unix(), []);

  // console.log(requestNumber, "requestNumber");

  let userData = {
    name,
    practiceName,
    phoneNumber,
    email,
  };

  const DisclaimerText = () => {
    return (
      <>
        <Row>
          <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6}></Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
            <h2 className="mb-5">Disclaimer</h2>
            <Divider className="mt-5 mb-5" />
            <ul className="concent-text">
              The following pro forma calculation is provided for informational
              purposes only. This pro forma calculation is based on Precipio’s
              internal data only from its clinical lab in New Haven, CT. These
              figures are estimates and projections generated using Precipio's
              own assumptions and methodologies and it is not intended to serve
              as financial or legal advice. You should note that actual
              reimbursement amounts may vary based on a variety of factors,
              including changes in market conditions, regulatory requirements,
              or other unforeseen circumstances. Customers are encouraged to use
              this pro forma calculation as a baseline and confirm their
              reimbursement amounts from their respective payors. Turnaround
              times (TAT) are based on simple calculations of laboratory volume,
              working days, and plate sizes. Actual TAT will depend on each
              customer’s laboratory’s decisions in terms of actual volume and
              fluctuations, workflow decisions, and products purchased. Precipio
              makes no representations or warranties, express or implied,
              regarding the accuracy, completeness, or reliability of the pro
              forma calculation, and we shall not be liable for any direct,
              indirect, incidental, special, or consequential damages arising
              out of or in any way connected with the use of this information.
              This disclaimer is an integral part of the pro forma calculation,
              and by continuing to use or rely on this information, you agree to
              be bound by the terms and conditions outlined herein. The customer
              is aware that the reagents sold by Precipio are designated as RUO
              products, and should be used according to FDA rules & regulations.
              Any economic analysis conducted as part of this pro forma
              calculation is done solely at the initiative of the customer and
              is conducted on a theoretical basis only. Should the customer wish
              to use these reagents clinically, it is their sole responsibility
              to follow the guidelines for converting RUO reagents to a
              Laboratory Developed Test (LDT).
            </ul>
          </Col>
        </Row>

        <br />
      </>
    );
  };

  const [dataList, setDataList] = useState<IPanels[]>([
    {
      key: "1",
      panel: "BCR-ABL1",
      sample: undefined,
    },
    {
      key: "2",
      panel: "ABL1 resistance",
      sample: undefined,
    },
    {
      key: "3",
      panel: "MPN",
      sample: undefined,
    },
    {
      key: "4",
      panel: "AML",
      sample: undefined,
    },
    {
      key: "5",
      panel: "Cytopenia",
      sample: undefined,
    },
    {
      key: "6",
      panel: "CLL",
      sample: undefined,
    },
  ]);

  const {
    mutate: mutateDownloadPdf,
    data: createRes,
    isError: isErrorPdf,
    error: errorRes,
    isLoading: downloadLoadingPdf,
  } = useMutation(downloadPdfApi);

  const {
    mutate: mutateDownloadExcel,
    data: createResponse,

    isError: isErrorExcel,
    error: errorResponse,
    isLoading: downloadLoadingExcel,
  } = useMutation(downloadExcelApi);
  const steps: IStepProps[] = [
    {
      title: "Acknowledgement",
      content: (
        <Acknowledgement
          accept={accept}
          setAccept={setAccept}
          DisclaimerText={DisclaimerText}
        />
      ),
    },
    {
      title: "Your Info",
      content: (
        <Contact
          practiceName={practiceName}
          setPracticeName={setPracticeName}
          phoneNumber={phoneNumber}
          setPhoneNumber={setPhoneNumber}
          name={name as any}
          setName={setName}
          email={email as any}
          setEmail={setEmail}
        />
      ),
    },
    {
      title: "Volume Input",
      content: (
        <Potential
          labDays={labDays}
          setLabDays={setLabDays}
          docNumber={docNumber}
          setDocNumber={setDocNumber}
          dataList={dataList}
          setDataList={setDataList}
          currentVolume={currentVolume}
          setCurrentVolume={setCurrentVolume}
        />
      ),
    },
    {
      title: "Pro Forma Results",
      content: (
        <CalculationComponent
          userData={userData}
          docNumber={docNumber}
          labDays={labDays}
          currentVolume={currentVolume}
          monthlyPanelVolume={dataList}
          requestNumber={requestNumber}
        />
      ),
    },
  ];

  const onChange = (value: number) => {
    setCurrent(value);
  };

  function handleNext() {
    if (current < 3) setCurrent((prev) => prev + 1);
  }

  function handleBack() {
    if (current > 0) setCurrent((prev) => prev - 1);
  }

  const handleCheckAccessCode = () => {
    const isValid = accessCode === "BH2023";
    setIsValidAccessCode(isValid);

    if (isValid) {
      setCurrent(0);
    } else {
      alert("Invalid access code. Please try again.");

      setAccessCode("");
    }
  };
  useEffect(()=>{
    if(current === 3){
      const dataListNew = dataList?.map((item:any)=>(
        {
          ...item,
          sample:item.sample ?? 0,
        }
      ))
      setDataList(dataListNew)
    }
  },[current, dataList])
  async function handleDownloadExcel() {
    try {
      let payload = {
        ...exportData,
        user_data: userData,
        number_of_doctor: docNumber,
        lab_operation_days: labDays,
        request_number: requestNumber,
        DisclaimerText: DisclaimerText,
      };
      mutateDownloadExcel(payload, {
        onSuccess: (res) => {
          console.log("API Response:", res);
          const blob = new Blob([res.data], {
            type: "application/vnd.ms-excel",
          });
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = `Proforma-result.xlsx`;
          link.click();
        },
      });
    } catch (error) {
      console.log(error, "error");
    }
  }

  async function handleDownloadPdf() {
    try {
      let payload = {
        ...exportData,
        user_data: userData,
        number_of_doctor: docNumber,
        lab_operation_days: labDays,
        request_number: requestNumber,
        DisclaimerText: DisclaimerText,
      };
      mutateDownloadPdf(payload, {
        onSuccess: (res) => {
          console.log("API Response:", res);
          const blob = new Blob([res.data], { type: "application/pdf" });
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = `Proforma-result.pdf`;
          link.click();
        },
      });
    } catch (error) {
      console.log(error, "error");
    }
  }

  const ButtonForAcknowledgement = () => (
    <>
      <Button
        type="primary"
        ghost
        onClick={() => {
          setAccept(false);
        }}
        className="cancel-button-style button-style"
      >
        Cancel
      </Button>
      <Button
        type="primary"
        onClick={handleNext}
        disabled={!accept}
        className="button-style"
      >
        Next
        <RightOutlined />
      </Button>
    </>
  );

  const ButtonForInfo = () => (
    <Button
      type="primary"
      onClick={handleNext}
      disabled={!phoneNumber || !email || !practiceName || !name}
      className="button-style"
    >
      Next
      <RightOutlined />
    </Button>
  );

  const ButtonForVolume = () => {
 

  
   

    // const isDataListFilled = dataList.every(
    //   (item) => item.sample !== 1 && item.sample > 0
    // );

    // console.log("isDataListFilled:", isDataListFilled);
    // console.log("lab Days", labDays);
    // console.log("docNumber", docNumber);

    const isButtonDisabled = currentVolume
      ? !labDays 
      : !labDays || !docNumber;

    return (
      <Button
        type="primary"
        onClick={handleNext}
        disabled={isButtonDisabled}
        className="button-style"
      >
        View Calculation
        <RightOutlined />
      </Button>
    );
  };

  return (
    <DashboardWrapper>
      <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <Card title="" bordered={true} className="card-element">
            <div className="card-div">
              <span className="span-element">Bloodhound Pro Forma</span>
              {current === 3 && (
                <>
                  <Row>
                    <Col xs={20} sm={12} md={12} lg={12} xl={12} xxl={12}>
                      <Button
                        loading={downloadLoadingPdf}
                        type="primary"
                        className="button-style space-button"
                        onClick={handleDownloadPdf}
                      >
                        Download PDF
                      </Button>
                    </Col>

                    {/* <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}> */}
                    {/* <Button
                        loading={downloadLoadingExcel}
                        type="primary"
                        className="button-style"
                        onClick={handleDownloadExcel}
                      >
                        Download Excel
                      </Button> */}
                    {/* </Col> */}
                  </Row>
                </>
              )}
            </div>
          </Card>
        </Col>
      </Row>

      <Row>
        <Card title="" bordered={true} className="card-element-two mt-10">
          <Col xl={0} lg={0} xxl={0} sm={0} md={0} xs={0} className="step-div">
            {isValidAccessCode && (
              <Steps
                style={{ width: "55%" }}
                type="navigation"
                size="small"
                current={current}
                onChange={onChange}
                items={steps?.map((item: IStepProps, index: number) => ({
                  key: index,
                  title: item?.title,
                  disabled: true,
                }))}
              />
            )}
          </Col>

          <div>
            {!isValidAccessCode ? (
              <AccessCode
                accessCode={accessCode}
                setAccessCode={setAccessCode}
                onUnlockButtonClick={handleCheckAccessCode}
              />
            ) : (
              <>
                <div style={{ minHeight: "60vh" }} className="mt-30">
                  {isValidAccessCode && (steps[current]?.content ?? <></>)}
                </div>

                <div className="main-div">
                  <div className="mt-30 content-div">
                    {current > 0 && current !== 3 && (
                      <Button
                        onClick={handleBack}
                        className="button-style calculation-back"
                        type="primary"
                        ghost
                      >
                        <LeftOutlined />
                        Go Back
                      </Button>
                    )}

                    {current === 0 && (
                      <>
                        <ButtonForAcknowledgement />
                      </>
                    )}
                    {current === 1 && <ButtonForInfo />}
                    {current === 2 && <ButtonForVolume />}
                    {current === 3 && (
                      <div className="calculation-div">
                        <Row>
                          <Col xs={18} sm={11} md={11} lg={11} xl={11} xxl={11}>
                            <Button
                              onClick={handleBack}
                              className="button-style space-button"
                              type="primary"
                              ghost
                            >
                              <LeftOutlined />
                              Go Back
                            </Button>
                          </Col>
                          {/* <Col xs={1} sm={1} md={1} lg={1} xl={1} xxl={1}></Col> */}
                          <Col xs={18} sm={12} md={12} lg={12} xl={12} xxl={12}>
                            <Button
                              loading={downloadLoadingPdf}
                              type="primary"
                              className="button-style space-button"
                              onClick={handleDownloadPdf}
                            >
                              Download PDF
                            </Button>
                          </Col>
                          {/* <Col xs={2} sm={2} md={2} lg={2} xl={2} xxl={2}></Col> */}
                          {/* <Col xs={18} sm={7} md={7} lg={7} xl={7} xxl={7}>
                            <Button
                              loading={downloadLoadingExcel}
                              type="primary"
                              className="button-style space-button"
                              onClick={handleDownloadExcel}
                            >
                              Download Excel
                            </Button>
                          </Col> */}
                        </Row>
                      </div>
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
        </Card>
      </Row>

      <ErrorHandling
        createRes={createRes}
        errorRes={errorRes}
        isError={isErrorPdf}
      />
      <ErrorHandling
        createRes={createResponse}
        errorRes={errorResponse}
        isError={isErrorExcel}
      />
    </DashboardWrapper>
  );
};

export default Dashboard;
