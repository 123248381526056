import { FC } from "react";
import { SecondWrapper } from "./styles";
import { PotentialNo } from "./PotentialNo";
import { Radio } from "antd";
import PotentialYes from "./PotentialYes";
import { IPanels } from "src/models";

interface IProps {
  labDays: string;
  setLabDays: (value: string) => void;
  docNumber: string;
  setDocNumber: (value: string) => void;
  dataList: IPanels[];
  setDataList: (value: IPanels[]) => void;
  currentVolume: boolean | null;
  setCurrentVolume: (value: boolean | null) => void;
}

export const Potential: FC<IProps> = ({
  labDays,
  setLabDays,
  docNumber,
  setDocNumber,
  dataList,
  setDataList,
  currentVolume,
  setCurrentVolume,
}) => {
  const handleRadioChange = (event: any) => {
    const value = event.target.value === "true";
    setCurrentVolume(value);
    setLabDays("");
    setDocNumber("");
  };

  return (
    <SecondWrapper>
      <div className="question">Do you know your monthly volume?</div>
      <Radio.Group
        className="options"
        name="radioGroup"
        value={String(currentVolume)}
        onChange={handleRadioChange}
      >
        <Radio value={"true"}>Yes</Radio>
        <Radio value={"false"}>No</Radio>
      </Radio.Group>
      {currentVolume ? (
        <PotentialYes
          labDays={labDays}
          setLabDays={setLabDays}
          dataList={dataList}
          setDataList={setDataList}
        />
      ) : (
        <PotentialNo
          docNumber={docNumber}
          setDocNumber={setDocNumber}
          labDays={labDays}
          setLabDays={setLabDays}
        />
      )}
    </SecondWrapper>
  );
};

export default Potential;
