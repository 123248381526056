import axios from "axios";

const localIP = "http://192.168.0.115:3003";

let NodeBaseURL = ``;
const host = window.location.hostname;

if (host && host === "bhas.precipiodx.com") {
  // set online server endpoints

  NodeBaseURL = `https://bhas.precipiodx.com/api`;
} else if (host && host === "bhas.precipiodx.com") {
  // Staging server endpoints
  NodeBaseURL = `https://bhas.precipiodx.com/api`;
} else {
  // local development
  NodeBaseURL = localIP + ":3003/api/v1";
  // NodeBaseURL = `https://bhas.precipiodx.com/api`;
}

const http = axios.create({
  baseURL: NodeBaseURL,
});

export const encriptionData = localStorage.getItem("auth");
// const access_token = encriptionData ? DecriptionData(encriptionData)?.result?.accessToken : false;

export const updateToken = (token: string) => {
  http.defaults.headers.common.Authorization = `Bearer ${token}`;
};

// if (access_token) {
//   updateToken(access_token);
// }

export { NodeBaseURL };
export default http;
