import styled from "styled-components";

export const NoWrapper = styled.div`
  width: 100%; /* Change the width to your desired size */
  margin: 0 auto; /* Centers the form horizontally */
  display: block;
  .doctor-number {
    // width: 25%;
  }
`;
