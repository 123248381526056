// import React, { FC } from "react";
// import { Input, Radio, Table } from "antd";
// import { ColumnsType } from "antd/es/table";
// import { YesWrapper } from "./styles";
// import { IPanels } from "src/models";

// interface IProps {
//   labDays: string;
//   setLabDays: (value: string) => void;

//   dataList: IPanels[];
//   setDataList: (value: IPanels[]) => void;
// }

// interface DataType {
//   key: React.Key;
//   panel: string;
//   sample: number;
// }

// export const PotentialYes: FC<IProps> = ({
//   labDays,
//   setLabDays,
//   dataList,
//   setDataList,
// }) => {
//   const columns: ColumnsType<DataType> = [
//     {
//       title: "Panel",
//       dataIndex: "panel",
//       render: (text: string) => (
//         <span style={{ color: "#1677ff" }}>{text}</span>
//       ),
//     },
//     {
//       title: "Monthly (Patient Samples)",
//       dataIndex: "sample",
//       render: (text: string, _: any, index: number) => (
//         <Input
//           type="number"
//           value={text}
//           onChange={(e: any) => handleChange(index, e.currentTarget.value)}
//         />
//       ),
//     },
//   ];

//   function handleChange(index: number, value: number) {
//     let tempData = [...dataList];
//     tempData[index].sample = value;
//     setDataList(tempData);
//   }

//   return (
//     <YesWrapper>
//       <p>Enter monthly volume:</p>
//       <Table
//         columns={columns}
//         dataSource={[...dataList]}
//         size="middle"
//         pagination={false}
//       />
//       <br />
//       <p>Select how many days per week your lab operates:</p>
//       <Radio.Group onChange={(e) => setLabDays(e.target.value)} value={labDays}>
//         <Radio value={5}>5 Days</Radio>
//         <Radio value={6}>6 Days</Radio>
//         <Radio value={7}>7 Days</Radio>
//       </Radio.Group>
//     </YesWrapper>
//   );
// };

// export default PotentialYes;

import React, { FC } from "react";
import { Col, Input, Radio, Row, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { YesWrapper } from "./styles";
import { IPanels } from "src/models";

interface IProps {
  labDays: string;
  setLabDays: (value: string) => void;

  dataList: IPanels[];
  setDataList: (value: IPanels[]) => void;
}

export const PotentialYes: FC<IProps> = ({
  labDays,
  setLabDays,
  dataList,
  setDataList,
}) => {
  function handleChange(index: number, value: string) {
    let tempData = [...dataList];

    // Ensure the value is a valid number or 0
    const parsedValue = Number(value) || 0;

    // Prevent negative values
    tempData[index].sample = Math.max(parsedValue, 0);

    setDataList(tempData);
  }

  const columns: ColumnsType<IPanels> = [
    {
      title: "Panel",
      dataIndex: "panel",
      render: (text: string) => (
        <span style={{ color: "#1677ff" }}>{text}</span>
      ),
    },
    {
      title: "Monthly (Patient Samples)",
      dataIndex: "sample",
      render: (text: string, _: any, index: number) => (
        <Input
          className="patient-samples"
          type="number"
          value={text}
          onChange={(e) => handleChange(index, e.target.value)}
        />
      ),
    },
  ];

  return (
    <YesWrapper>
      <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <p>Enter monthly volume:</p>

          <Table
            columns={columns}
            dataSource={[...dataList]}
            size="middle"
            pagination={false}
          />
          <br />
          <p>Select how many days per week your lab operates:</p>
          <Radio.Group
            onChange={(e) => setLabDays(e.target.value)}
            value={labDays}
          >
            <Radio value={5}>5 Days</Radio>
            <Radio value={6}>6 Days</Radio>
            <Radio value={7}>7 Days</Radio>
          </Radio.Group>
        </Col>
      </Row>
    </YesWrapper>
  );
};

export default PotentialYes;
