import { ColumnsType } from "antd/es/table";
import { FC } from "react";
import { Col, Row, Table } from "antd";
import { TableWrapper } from "./styles";

interface IProps {
  data: any[];
  loading: boolean;
}

export const PricingTable: FC<IProps> = ({ data, loading }) => {
  const columns: ColumnsType<any> = [
    {
      title: <div style={{ fontWeight: "bold" }}>Panel</div>,
      dataIndex: "panel",
      width: "180px",
    },
    {
      title: (
        <div style={{ fontWeight: "bold", textAlign: "center" }}>Pricing</div>
      ),
      dataIndex: "pricing",
      width: "100px",
      render: (value: any) => (
        <div style={{ textAlign: "center" }}>
          <span>$ {value.toLocaleString("en-US")}</span>
        </div>
      ),
    },
    {
      title: <div style={{ fontWeight: "bold", textAlign: "center" }}>CMS</div>,
      dataIndex: "cms",
      width: "100px",
      render: (value: any) => (
        <div style={{ textAlign: "center" }}>
          <span>$ {value.toLocaleString("en-US")}</span>
        </div>
      ),
    },
    {
      title: (
        <div style={{ fontWeight: "bold", textAlign: "center" }}>GM ($)</div>
      ),
      dataIndex: "gms",
      width: "100px",
      render: (value: any) => (
        <div style={{ textAlign: "center" }}>
          <span>$ {value.toLocaleString("en-US")}</span>
        </div>
      ),
    },
    {
      title: (
        <div style={{ fontWeight: "bold", textAlign: "center" }}>GM (%)</div>
      ),
      dataIndex: "gm_percentage",
      width: "100px",
      render: (value: any) => (
        <div style={{ textAlign: "center" }}>
          <span> {value.toLocaleString("en-US")}</span>
        </div>
      ),
    },
  ];

  return (
    <>
      <h3 className="mt-10">Pricing and Reimbursement</h3>
      <TableWrapper>
        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
            <Table
              className="pricing-table"
              key={"id"}
              size="small"
              bordered
              dataSource={!loading ? data : []}
              columns={columns}
              pagination={false}
              loading={loading}
              scroll={{ x: 600 }}
            />
          </Col>
        </Row>
      </TableWrapper>
    </>
  );
};
