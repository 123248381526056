import { ColumnsType } from "antd/es/table";
import { FC } from "react";
import { Table } from "antd";
import { TableWrapper } from "./styles";

interface IProps {
  data: any[];
  loading: boolean;
}

export const TatTable: FC<IProps> = ({ data, loading }) => {
  const columns: ColumnsType<any> = [
    {
      title: <div style={{ fontWeight: "bold" }}>Panel</div>,
      dataIndex: "panel",
      width: "180px",
    },
    {
      title: (
        <div style={{ fontWeight: "bold", textAlign: "center" }}>
          Monthly Volume
        </div>
      ),
      dataIndex: "volume",
      width: "100px",
      render: (value: number) => (
        <div style={{ textAlign: "center" }}>
          {value ? Math.round(value) : ""}
        </div>
      ),
    },
    {
      title: (
        <div style={{ fontWeight: "bold", textAlign: "center" }}>
          Min Size Plate
        </div>
      ),
      dataIndex: "combined_minSizePlate",
      width: "100px",
      render: (value: number) => (
        <div style={{ textAlign: "center" }}>{value ? value : ""}</div>
      ),
    },
    {
      title: (
        <div style={{ fontWeight: "bold", textAlign: "center" }}>
          Runs/Month
        </div>
      ),
      dataIndex: "runs_per_month",
      width: "100px",
      render: (value: number) => (
        <div style={{ textAlign: "center" }}>{value ? value : ""}</div>
      ),
    },
    {
      title: (
        <div style={{ fontWeight: "bold", textAlign: "center" }}>
          Expected TAT (days)
        </div>
      ),
      dataIndex: "tat",
      width: "100px",
      render: (value: number) => (
        <div style={{ textAlign: "center" }}>
          {value ? (value === Infinity ? "" : value) : ""}
        </div>
      ),
    },
  ];

  return (
    <>
      <h3 className="mt-10">TAT Calculation Table</h3>
      <TableWrapper>
        <Table
          className="tat-table"
          size="small"
          bordered
          dataSource={!loading ? data : []}
          columns={columns}
          pagination={false}
          loading={loading}
          scroll={{ x: 600 }}
        />
      </TableWrapper>
    </>
  );
};
