import styled from "styled-components";

export const SecondWrapper = styled.div`
  width: 40%; /* Change the width to your desired size */
  margin: 0 auto; /* Centers the form horizontally */
  display: block;

  .question {
    padding-bottom: 1%;
  }
  .options {
    padding-bottom: 2%;
  }
  .yesOption {
    padding-right: 2%;
  }
`;
