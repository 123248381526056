import React, { FC } from "react";

import { AcknowledgementWrapper } from "./styles";
import { Checkbox, Col, Row } from "antd";
import { CheckboxChangeEvent } from "antd/es/checkbox";

interface IProps {
  accept: boolean;
  setAccept: (value: boolean) => void;
  DisclaimerText: () => JSX.Element;
}

export const Acknowledgement: FC<IProps> = ({
  accept,
  setAccept,
  DisclaimerText,
}) => {
  const onChange = (e: CheckboxChangeEvent) => {
    setAccept(!accept);
  };

  return (
    <AcknowledgementWrapper>
      <DisclaimerText></DisclaimerText>
      <Row>
        <Col xs={6} sm={6} md={6} lg={6} xl={6} xxl={6}></Col>
        <Col
          xs={20}
          sm={20}
          md={18}
          lg={14}
          xl={14}
          xxl={14}
          className="concent"
        >
          <Checkbox
            checked={accept}
            onChange={onChange}
            className="circular-checkbox"
          >
            <b>I Accept the terms and conditions.</b>
          </Checkbox>
        </Col>
      </Row>
    </AcknowledgementWrapper>
  );
};

export default Acknowledgement;
