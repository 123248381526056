import moment from "moment";
import { FC, useEffect, useState } from "react";
import { Col, Descriptions, DescriptionsProps, Divider, Row } from "antd";

import { TatTable } from "./Tables/tatTable";
import { PricingTable } from "./Tables/pricingTable";
import { ProformaTable } from "./Tables/proformaTable";
import { IPanels } from "src/models";
import { useGlobalContext } from "src/hooks/globalContext";
import { CalculationWrapper } from "./styles";
import {
  constMonthlyVolumes,
  consumeablePerSample,
  minSizePlate,
  panelAnnuallyList as panelAnnuallyList,
  panelMonthlyList,
  panelProFormaLabels,
  pricingReimbursement,
  reagentPerSample,
} from "./config";

interface IProps {
  userData: any;
  labDays: number;
  docNumber: number;
  currentVolume: boolean | null;
  monthlyPanelVolume: IPanels[];
  requestNumber: number;
}

export const CalculationComponent: FC<IProps> = ({
  userData,
  labDays,
  docNumber,
  currentVolume,
  monthlyPanelVolume,
  requestNumber,
}) => {
  const { setExportData } = useGlobalContext();
  const [loadingCalculation, setLoadingCalculation] = useState<boolean>(true);

  useEffect(() => {
    setTimeout(() => {
      setLoadingCalculation(false);
    }, 800);
  }, []);

  const detailItems: DescriptionsProps["items"] = [
    {
      key: "1",
      label: "Customer",
      children: userData.practiceName,
    },
    {
      key: "2",
      label: "Customer contact person",
      children: userData.name,
    },
    {
      key: "3",
      label: "Customer email address",
      children: userData.email,
    },

    {
      key: "4",
      label: "Customer phone number",
      children: userData.phoneNumber,
    },
    {
      key: "5",
      label: "Date",
      children: moment().format("MM/DD/YYYY"),
    },

    {
      key: "6",
      label: "Request number",
      children: requestNumber,
    },

    {
      key: "7",
      label: "Lab's Weekly Operating Days",
      children: labDays,
    },
    (!currentVolume as any) && {
      key: "8",
      label: "Number of Doctors",
      children: docNumber,
    },
  ];

  //---------------------------TAT table's Data--------------------------------------

  const tatDataList = monthlyPanelVolume?.map(
    (item: IPanels, index: number) => {
      let tempVolume = currentVolume
        ? item?.sample
        : docNumber * constMonthlyVolumes[index].value;
      // console.log("----------------------------------------------------", item);
      // console.log(
      //   "-----------volume-----------------------------------------",
      //   tempVolume
      // );

      if (item?.key === "4") {
        return {
          key: index,
          panel: item?.panel,
          volume: tempVolume ?? 0,
          runs_per_month: tempVolume
            ? Math.round(tempVolume / minSizePlate[index].value)
            : 0,
          tat: tempVolume ? 1 : 0,
          combined_minSizePlate: tempVolume ? minSizePlate[index].value : 0,
        };
      } else {
        return {
          key: index,
          panel: item?.panel,
          volume: tempVolume ?? 0,
          runs_per_month: tempVolume
            ? Math.round(tempVolume / minSizePlate[index].value)
            : 0,
          tat: tempVolume
            ? Math.ceil(30 / Math.round(tempVolume / minSizePlate[index].value))
            : 0,
          combined_minSizePlate: tempVolume ? minSizePlate[index].value : 0,
        };
      }
    }
  );

  //---------------------------- Pricing and Reimbursement Table ---------------------------------------

  const pricingDataList = pricingReimbursement?.map(
    (item: any, index: number) => {
      return {
        key: index,
        panel: item?.panel,
        pricing: item?.pricing.toFixed(2),
        cms: item?.cms.toFixed(2),
        gms: (
          pricingReimbursement[index].cms - pricingReimbursement[index].pricing
        ).toFixed(2),
        gm_percentage:
          Math.round(
            ((pricingReimbursement[index].cms -
              pricingReimbursement[index].pricing) /
              pricingReimbursement[index].cms) *
              100
          ) + "%",
      };
    }
  );

  // const pricingDataList = pricingReimbursement?.map(
  //   (item: any, index: number) => {
  //     return {
  //       key: index,
  //       panel: item?.panel,
  //       pricing: `$ ${item?.pricing}`,
  //       cms: `$ ${item?.cms}`,
  //       gms: `$ ${(
  //         pricingReimbursement[index].cms - pricingReimbursement[index].pricing
  //       ).toFixed(2)}`,
  //       gm_percentage:
  //         Math.round(
  //           ((pricingReimbursement[index].cms -
  //             pricingReimbursement[index].pricing) /
  //             pricingReimbursement[index].cms) *
  //             100
  //         ) + "%",
  //     };
  //   }
  // );

  //--------------------- All calculations below are for pro-forma table --------------------------------

  function calculateCombinedValue(obj: any, attrib: string) {
    let totalSum = 0;

    for (const key in obj) {
      if (key.endsWith(attrib)) {
        totalSum += obj[key];
      }
    }
    return totalSum;
  }

  let allDataList: any[] = [];

  //For Volume Row
  const panelVolumeList: number[] = tatDataList?.map((item) => item?.volume);
  const panelVolumeRow: any = {};
  panelMonthlyList?.forEach((item: any, index: number) => {
    panelVolumeRow[item] = panelVolumeList[index];
    panelVolumeRow[panelAnnuallyList[index]] = panelVolumeList[index] * 12;
  });

  let firstRow = {
    ...panelVolumeRow,
    combined_monthly: calculateCombinedValue(panelVolumeRow, "_monthly"),
    combined_annually: calculateCombinedValue(panelVolumeRow, "_annually"),
  };

  allDataList.push(firstRow);

  //For CMS reimbursement Row
  const cmsReimbursementRow: any = {};
  panelMonthlyList?.forEach((item: any, index: number) => {
    cmsReimbursementRow[item] = pricingReimbursement[index].cms;
    cmsReimbursementRow[panelAnnuallyList[index]] =
      pricingReimbursement[index].cms;
  });

  let secondRow = {
    ...cmsReimbursementRow,
    // combined_monthly: calculateCombinedValue(cmsReimbursementRow, "_monthly"),
    // combined_annually: calculateCombinedValue(cmsReimbursementRow, "_annually"),
    combined_monthly: "",
    combined_annually: "",
  };

  allDataList.push(secondRow);

  //For Revenues row
  const revenuesRow: any = {};
  panelMonthlyList?.forEach((item: any, index: number) => {
    revenuesRow[item] = panelVolumeRow[item] * cmsReimbursementRow[item];
    revenuesRow[panelAnnuallyList[index]] =
      panelVolumeRow[panelAnnuallyList[index]] *
      cmsReimbursementRow[panelAnnuallyList[index]];
  });

  let thirdRow = {
    ...revenuesRow,
    combined_monthly: calculateCombinedValue(revenuesRow, "_monthly"),
    combined_annually: calculateCombinedValue(revenuesRow, "_annually"),
  };
  allDataList.push(thirdRow);

  //Total Reagent
  const totalReagent: any = {};
  panelMonthlyList?.forEach((item: any, index: number) => {
    totalReagent[item] =
      panelVolumeList[index] * reagentPerSample[index].reagent;
    totalReagent[panelAnnuallyList[index]] =
      panelVolumeList[index] * reagentPerSample[index].reagent * 12;
  });

  //Total Consumeables
  const totalComsumeAbles: any = {};
  panelMonthlyList?.forEach((item: any, index: number) => {
    totalComsumeAbles[item] =
      panelVolumeList[index] * consumeablePerSample[index].consume_able;
    totalComsumeAbles[panelAnnuallyList[index]] =
      panelVolumeList[index] * consumeablePerSample[index].consume_able * 12;
  });

  //Reagent Costs Row
  const reagentCostRow: any = {};
  panelMonthlyList?.forEach((item: any, index: number) => {
    reagentCostRow[item] = totalReagent[item] + totalComsumeAbles[item];
    reagentCostRow[panelAnnuallyList[index]] =
      totalReagent[panelAnnuallyList[index]] +
      totalComsumeAbles[panelAnnuallyList[index]];
  });

  let fourthRow = {
    ...reagentCostRow,
    combined_monthly: calculateCombinedValue(reagentCostRow, "_monthly"),
    combined_annually: calculateCombinedValue(reagentCostRow, "_annually"),
  };
  allDataList.push(fourthRow);

  //Profit Row
  const profitRow: any = {};
  panelMonthlyList?.forEach((item: any, index: number) => {
    profitRow[item] = revenuesRow[item] - reagentCostRow[item];
    profitRow[panelAnnuallyList[index]] =
      revenuesRow[panelAnnuallyList[index]] -
      reagentCostRow[panelAnnuallyList[index]];
  });

  let fifthRow = {
    ...profitRow,
    combined_monthly: calculateCombinedValue(profitRow, "_monthly"),
    combined_annually: calculateCombinedValue(profitRow, "_annually"),
  };
  allDataList.push(fifthRow);

  //Profit Margin Row
  const profitMarginRow: any = {};
  panelMonthlyList?.forEach((item: any, index: number) => {
    profitMarginRow[item] = (profitRow[item] / revenuesRow[item]) * 100;
    profitMarginRow[panelAnnuallyList[index]] =
      (profitRow[panelAnnuallyList[index]] /
        revenuesRow[panelAnnuallyList[index]]) *
      100;
  });

  let sixthRow = {
    ...profitMarginRow,
    combined_monthly:
      (allDataList[4]["combined_monthly"] /
        allDataList[2]["combined_monthly"]) *
      100,
    combined_annually:
      (allDataList[4]["combined_annually"] /
        allDataList[2]["combined_annually"]) *
      100,
  };
  allDataList.push(sixthRow);

  //Final Data
  const proformaDataList: any = panelProFormaLabels?.map(
    (item: any, index: number) => ({
      key: index,
      panel: item?.name,
      ...allDataList[index],
    })
  );

  useEffect(() => {
    let tempdata = {
      tatData: tatDataList,
      priceData: pricingDataList,
      proformaData: proformaDataList,
    };
    setExportData(tempdata);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //giving a green background color to the profit margin row

  // const getRowProfit = (record: any) => {
  //   return record.panel === "Profit" ? "highlighted-row" : "";
  // };

  return (
    <CalculationWrapper>
      <Descriptions
        className="custom-description mb-10 "
        column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 2, xs: 1 }}
        size={"small"}
        items={detailItems}
      />

      <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <TatTable loading={loadingCalculation} data={tatDataList ?? []} />
        </Col>
      </Row>
      <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <PricingTable
            loading={loadingCalculation}
            data={pricingDataList ?? []}
          />
        </Col>
      </Row>

      <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <ProformaTable
            loading={loadingCalculation}
            data={proformaDataList ?? []}
            // rowClassName={getRowProfit}
          />
        </Col>
      </Row>

      <>
        <Row>
          <Col>
            <h3 className="mb-5">Disclaimer</h3>
          </Col>
        </Row>
        <Divider className="mt-5 mb-5" />
        <Row>
          <Col>
            <ul className="concent-text">
              The following pro forma calculation is provided for informational
              purposes only. This pro forma calculation is based on Precipio’s
              internal data only from its clinical lab in New Haven, CT. These
              figures are estimates and projections generated using Precipio's
              own assumptions and methodologies and it is not intended to serve
              as financial or legal advice. You should note that actual
              reimbursement amounts may vary based on a variety of factors,
              including changes in market conditions, regulatory requirements,
              or other unforeseen circumstances. Customers are encouraged to use
              this pro forma calculation as a baseline and confirm their
              reimbursement amounts from their respective payors. Turnaround
              times (TAT) are based on simple calculations of laboratory volume,
              working days, and plate sizes. Actual TAT will depend on each
              customer’s laboratory’s decisions in terms of actual volume and
              fluctuations, workflow decisions, and products purchased. Precipio
              makes no representations or warranties, express or implied,
              regarding the accuracy, completeness, or reliability of the pro
              forma calculation, and we shall not be liable for any direct,
              indirect, incidental, special, or consequential damages arising
              out of or in any way connected with the use of this information.
              This disclaimer is an integral part of the pro forma calculation,
              and by continuing to use or rely on this information, you agree to
              be bound by the terms and conditions outlined herein. The customer
              is aware that the reagents sold by Precipio are designated as RUO
              products, and should be used according to FDA rules & regulations.
              Any economic analysis conducted as part of this pro forma
              calculation is done solely at the initiative of the customer and
              is conducted on a theoretical basis only. Should the customer wish
              to use these reagents clinically, it is their sole responsibility
              to follow the guidelines for converting RUO reagents to a
              Laboratory Developed Test (LDT).
            </ul>
          </Col>
        </Row>
      </>
    </CalculationWrapper>
  );
};
